import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';

import FundCard from '../modules/FundCard';
import InvestmentUpdatesList from '../modules/InvestmentUpdatesList';
import InvestmentDealsList from '../modules/InvestmentDealsList';

import CompanyCard from '../modules/CompanyCard';

import ChartOrTableViewer from '../modules/ChartOrTableViewer';

import DataRooms from '../investments/data_rooms';
import FundAddButton from '../../tab_view/fund/FundAddButton';
import Tabs from '../funds/dashboard/Tabs';
import Button from '../../Button';
import MigrateToQuoroom from './MigrateToQuoroom';


class InvestorHome extends Component {
  static propTypes = {
    investorHome: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    setTimeAgo: PropTypes.func.isRequired,
    removeItem: PropTypes.func.isRequired,
    createFund: PropTypes.func.isRequired,
    deleteFund: PropTypes.func.isRequired,
    getChartData: PropTypes.func.isRequired,
    getDashboardData: PropTypes.func.isRequired,
    getCardsData: PropTypes.func.isRequired,
    getUpdatesData: PropTypes.func.isRequired,
    getDealRoomsData: PropTypes.func.isRequired,
    getDocumentsData: PropTypes.func.isRequired,
    updateChartData: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    const { investorHome } = this.props;

    this.container = React.createRef();

    this.state = {
      currentActivityTab: 'updates',
      currentOverviewTab: investorHome.current_overview_tab,
      scroll: 0,
      arrowDisable: 'right',
      maxScroll: 0.0,
      selectedCard: investorHome.investor_plan ? (investorHome.investor_plan === 'paying' || investorHome.investor_plan === 'trialing' ? 'portfolio' : 'companies') : (investorHome.angel ? 'companies' : 'portfolio'),
    };
  }

  updateScrollContainer = () => {
    const both = (this.container.current.scrollWidth - this.container.current.clientWidth) <= 0 ? 'both' : 'right';

    this.setState(prevState => ({
      ...prevState.state,
      arrowDisable: both,
      maxScroll: (this.container.current.scrollWidth - this.container.current.clientWidth),
    }));
  }

  componentDidMount=() => {
    this.updateScrollContainer();

    this.lazyLoading();
  }

  componentWillReceiveProps=() => {
    this.setState(prevState => ({
      ...prevState.state,
    }));
  }

  lazyLoading = () => {
    const {
      investorHome,
      getCardsData,
      getUpdatesData,
      getDealRoomsData,
      getDashboardData,
      getDocumentsData,
    } = this.props;

    getCardsData();

    (async () => {
      await getDashboardData('company_info');
      await getDashboardData('company_info', { lazyLoadingLayer: 'remaining' });
    })();

    (async () => {
      await getDashboardData('investment_info');
      await getDashboardData('investment_info', { lazyLoadingLayer: 'remaining' });
    })();

    getDashboardData('kpis_info', { timeAgo: investorHome.kpis_info.time_ago, frequency: investorHome.kpis_info.frequency });
    getUpdatesData();
    getDealRoomsData();
    getDocumentsData();
  }

  scrollToLeft= () => {
    let { scroll } = this.state;

    if ((scroll) < (this.container.current.scrollWidth - this.container.current.clientWidth)) {
      this.setState(prevState => ({
        ...prevState.state,
        scroll: scroll + 120,
        arrowDisable: '',
      }), () => {
        for (let i = scroll; i <= this.state.scroll; i += 1) {
          setTimeout(() => { this.container.current.scrollLeft = i; }, (i - scroll) * 2);
        }
        scroll += 120;
        if ((scroll) >= (this.container.current.scrollWidth - this.container.current.clientWidth)) {
          const both = (this.container.current.scrollWidth - this.container.current.clientWidth) <= 0 ? 'both' : null;
          if (both) {
            this.setState(prevState => ({
              ...prevState.state,
              arrowDisable: 'both',
            }));
          } else {
            this.setState(prevState => ({
              ...prevState.state,
              arrowDisable: 'left',
            }));
          }
        }
      });
    }
  }

  scrollToRight= () => {
    let { scroll } = this.state;
    if ((scroll) > 0) {
      this.setState(prevState => ({
        ...prevState.state,
        scroll: scroll - 120,
        arrowDisable: '',
      }), () => {
        for (let i = scroll; i >= this.state.scroll; i -= 1) {
          setTimeout(() => { this.container.current.scrollLeft = i; }, (scroll - i) * 2);
        }
        scroll -= 120;
        if ((scroll) <= 0) {
          const both = (this.container.current.scrollWidth - this.container.current.clientWidth) <= 0 ? 'both' : null;
          if (both) {
            this.setState(prevState => ({
              ...prevState.state,
              arrowDisable: 'both',
            }));
          } else {
            this.setState(prevState => ({
              ...prevState.state,
              arrowDisable: 'right',
            }));
          }
        }
      });
    }
  }

  handleScroll = () => {
    this.setState({ scroll: this.container.current.scrollLeft });
  }

  handleTimeChangeForKpisTab = async ({ timeAgo = null, frequency = null }, kpiOptions = {}) => {
    const { setTimeAgo, getDashboardData, investorHome } = this.props;

    const time = timeAgo !== null && timeAgo !== undefined ? timeAgo : investorHome.kpis_info.time_ago;
    const freq = frequency !== null && frequency !== undefined ? frequency : investorHome.kpis_info.frequency;
    const options = {
      ...kpiOptions,
      kpiMetric: kpiOptions.kpiMetric !== null && kpiOptions.kpiMetric !== undefined ? kpiOptions.kpiMetric : investorHome.kpis_info.chart_data.chosen_kpi,
    };

    await setTimeAgo(time, freq);
    await getDashboardData('kpis_info', { timeAgo: time, frequency: freq, ...options });
  }

  handleTabChange = (tab) => {
    const { trackEvent } = this.props;

    this.setState({ currentOverviewTab: tab }, () => {
      trackEvent('home_page_events', `${tab}_tab_select`);
    });
  }

  handlechartTypeChange = (tab, chartType) => {
    const { trackEvent } = this.props;

    trackEvent('home_page_events', `${tab}_${chartType}_view`);
  }

  sendIntercomEvent = () => {
    const { investorHome } = this.props;

    if (investorHome.investor_plan && investorHome.investor_plan === 'free') {
      if (typeof Intercom !== 'undefined') {
        Intercom('trackEvent', 'Free trial with up to X companies');
      }
    }
  }

  render() {
    const {
      investorHome,
      removeItem,
      createFund,
      deleteFund,
      getChartData,
      getDashboardData,
      updateChartData,
      currentUser,
    } = this.props;

    const {
      currentActivityTab,
      currentOverviewTab,
      arrowDisable,
      maxScroll,
      selectedCard,
    } = this.state;

    let { scroll } = this.state;

    if (scroll > maxScroll) {
      scroll = maxScroll;
    }

    let color = '';
    if (investorHome.color_theme) {
      if (Color(investorHome.color_theme).white() <= 80) {
        color = investorHome.color_theme;
      } else {
        color = window.ColorVariables.colorBlue;
      }
    }

    const companyCardWidgetsHeader = (first = false) => (
      <>
        {((!investorHome.investor_plan && investorHome.angel) || investorHome.investor_plan) &&
          <div
            className={`h4 fw400 pl1 cursor-pointer ${selectedCard === 'companies' ? 'text-gray' : 'text-light-gray'} ${first ? 'mr2' : ''}`}
            onClick={() => this.setState({ selectedCard: 'companies' }, () => this.updateScrollContainer())}
          >
            Connected Companies
          </div>
        }
      </>
    );

    const portfolioWidgetsHeader = (first = false) => (
      <>
        {((!investorHome.investor_plan && !investorHome.angel) || investorHome.investor_plan) &&
          <div
            className={`flex h4 fw400 pl1 cursor-pointer ${selectedCard === 'portfolio' ? 'text-gray' : 'text-light-gray'} ${first ? 'mr2' : ''}`}
            onClick={() => { this.setState({ selectedCard: 'portfolio' }, () => this.updateScrollContainer()); this.sendIntercomEvent(); }}
          >
            <div className="flex flex-column items-center">
              Funds/Portfolios
              {selectedCard === 'portfolio' &&
                <span className="h6">(In {investorHome.currency})</span>
              }
              {/* {investorHome.investor_plan === 'trialing' &&
                <span className="h6 text-light-gray">Limited-time Trial</span>
              } */}
            </div>
            {/* {investorHome.investor_plan && investorHome.investor_plan === 'free' && <span className="h6 ml1 text-red">{`(Try-for-free with up to ${investorHome.max_investments} companies)`}</span>} */}
          </div>
        }
      </>
    );

    return (
      <div className="container container--report lg-px2 mt3 pt3 flex flex-column">
        <MigrateToQuoroom email={currentUser.email} connectToQuoroom={currentUser.connectToQuoroom} investorId={currentUser.investorId} />
        <div className="flex">
          {(investorHome.investor_plan && (investorHome.investor_plan === 'paying' || investorHome.investor_plan === 'trialing')) ?
            <>
              {portfolioWidgetsHeader(true)}
              {companyCardWidgetsHeader()}
            </>
            :
            <>
              {companyCardWidgetsHeader(true)}
              {portfolioWidgetsHeader()}
            </>
          }
        </div>

        {selectedCard === 'companies' ?

          // Company Cards go in here
          <div className="flex flex-row mt2">
            <div ref={this.container} className="card-container sm-vw-85 flex flex-justify-between mb1 p1 flex-nowrap overflow-x-auto overflow-scrolling-touch" onScroll={this.handleScroll}>
              {investorHome.companies.map(company => (
                <CompanyCard
                  key={company.id}
                  company={company}
                  removeItem={removeItem}
                  investor={{ id: investorHome.investor_id, angel: investorHome.angel }}
                  scroll={scroll}
                  parentRef={this.container}
                />
              ))}
              <CompanyCard
                customInvestmentCount={investorHome.custom_investment_count}
                investor={{ id: investorHome.investor_id, angel: investorHome.angel, can_create_custom_companies: investorHome.can_create_custom_companies, can_create_real_companies: investorHome.can_create_real_companies }}
                bNewConnection
                parentRef={this.container}
              />
            </div>
            <div className="mr2 flex flex-row  text-light-gray" style={{ Height: '180px', Width: '180px' }}>
              <div className="relative flex items-center left-25">
                <div>
                  <div className="h1 mb2 cursor-pointer">
                    <i className={`fa fa-arrow-right ${(arrowDisable === 'left' || arrowDisable === 'both') ? 'cursor-default text-extra-light-gray' : ''}`} onClick={this.scrollToLeft} />
                  </div>
                  <div className="h1 cursor-pointer">
                    <i className={`fa fa-arrow-left ${(arrowDisable === 'right' || arrowDisable === 'both') ? 'cursor-default text-extra-light-gray' : ''}`} onClick={this.scrollToRight} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          :
          // Fund Cards go in here
          <div className="flex flex-column mt2">
            <div className="flex flex-row">
              <div ref={this.container} className="card-container sm-vw-85 flex flex-justify-between mb1 p1 flex-nowrap overflow-x-auto overflow-scrolling-touch">
                {investorHome.funds.map((fund, i) => {
                  if (i === 0) {
                    return (
                      <React.Fragment key={fund.id}>
                        <FundCard
                          fund={fund}
                          investorId={investorHome.investor_id}
                          themeColor={color}
                          admin={investorHome.admin}
                          deleteFund={deleteFund}
                        />
                        <div className="border-left border-gallery pr2" style={{ height: '100%' }} />
                      </React.Fragment>
                    );
                  }
                  return (
                    <FundCard
                      key={fund.id}
                      fund={fund}
                      investorId={investorHome.investor_id}
                      themeColor={color}
                      admin={investorHome.admin}
                      deleteFund={deleteFund}
                    />
                  );
                })}
                { investorHome.admin &&
                  <div className="ml2 sm-hide">
                    <div className="mr2 flex flex-column text-light-gray" style={{ height: '100%', minWidth: '180px' }}>
                      <div
                        className="flex col-12 items-center flex-column flex-justify-center border--dashed border-light-gray"
                        style={{ height: '80%' }}
                      >
                        <div className="flex items-center">
                          <FundAddButton buttonText="New Fund" buttonClass="" createFundFunction={createFund} planAllowInvite={investorHome.can_create_funds} />
                        </div>
                      </div>
                      <div className="flex col-12 items-center flex-column flex-justify-center mt1 border--dashed border-light-gay" style={{ height: '20%' }}>
                        <div className="flex items-center cursor-pointer ">
                          <a href={investorHome.manage_funds_path} className="text-light-gray">
                            <i className="fa fa-cog mr1" />
                            <span>Manage Funds</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>

              <div className="mr2 flex flex-row  text-light-gray sm-hide" style={{ Height: '180px', Width: '209px' }}>
                <div className="relative flex items-center">
                  <div>
                    <div className="h1 mb2 cursor-pointer">
                      <i className={`fa fa-arrow-right ${(arrowDisable === 'left' || arrowDisable === 'both') ? 'cursor-default text-extra-light-gray' : ''}`} onClick={this.scrollToLeft} />
                    </div>
                    <div className="h1 cursor-pointer">
                      <i className={`fa fa-arrow-left ${(arrowDisable === 'right' || arrowDisable === 'both') ? 'cursor-default text-extra-light-gray' : ''}`} onClick={this.scrollToRight} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="flex flex-column px1 mt1">
          {investorHome.show_benchmarking &&
            <Button classNames="flex flex-justify-center btn btn-outline btn-big border-alto bg-white sm-mb1 sm-col-12 col-2 mb2">
              <div className="relative">
                <span className="desktop__notification--bell" data-behavior="hide-bubble" style={{ borderRadius: '30%', width: '25px', top: '-32px', left: '110px', zIndex: 1 }}>
                  Alpha
                </span>
              </div>
              <a href={`/investor/${investorHome.investor_id}/benchmarkings`} className="text-blue fw400">BenchMarking</a>
            </Button>
          }
          <span className="h4 fw400 text-gray">My Overview</span>
          <Tabs
            dashboardTabs={[{ tab_name: 'infos' }, { tab_name: 'investments' }, { tab_name: 'kpis' }]}
            activeTab={currentOverviewTab}
            setTabAndResetSorting={(tabName) => this.handleTabChange(tabName)}
            timeFrameChangeForKpisTab={(frequency) => this.handleTimeChangeForKpisTab({ frequency })}
            color={color}
            fundDashboard={false}
            frequency={investorHome.kpis_info.frequency}
          />

          <div className="my2 pb2 bs-around bg-white">
            <ChartOrTableViewer
              show={currentOverviewTab === 'infos'}
              themeColor={color}
              chartData={investorHome.company_info.chart_data}
              dashboardData={investorHome.company_info.dashboard_data}
              getChartData={(filterType) => updateChartData('company_info', { filterType })}
              investorId={investorHome.investor_id}
              tableType="company_info"
              tabScroll
              legendValues="#"
              exportDataURL={investorHome.company_info.export_url}
              canExport={investorHome.can_export}
              maxInvestments={investorHome.max_investments}
              chartTypeEventTracker={(chartType) => this.handlechartTypeChange('infos', chartType)}
            />

            <ChartOrTableViewer
              show={currentOverviewTab === 'investments'}
              themeColor={color}
              chartData={investorHome.investment_info.chart_data}
              dashboardData={investorHome.investment_info.dashboard_data}
              getDashboardData={() => getDashboardData('investment_info')}
              investorId={investorHome.investor_id}
              totalsInfo={{ currency: investorHome.currency, ratesUpdatedAt: investorHome.rates_updated_at }}
              legendTitle="Company"
              legendValues="Shares Value"
              tableType="investment_info"
              tabScroll
              exportDataURL={investorHome.investment_info.export_url}
              canExport={investorHome.can_export}
              maxInvestments={investorHome.max_investments}
              chartTypeEventTracker={(chartType) => this.handlechartTypeChange('infos', chartType)}
            />

            <ChartOrTableViewer
              show={currentOverviewTab === 'kpis'}
              themeColor={color}
              chartData={investorHome.kpis_info.chart_data}
              dashboardData={investorHome.kpis_info.dashboard_data}
              timeManage={{ timeAgo: investorHome.kpis_info.time_ago, frequency: investorHome.kpis_info.frequency }}
              getChartData={(kpiMetric, timeFrame, kpiType) => getChartData('kpis_info', { kpiMetric, timeFrame, frequency: investorHome.kpis_info.frequency, kpiType })}
              getDashboardData={(timeAgo, kpiOptions = {}) => this.handleTimeChangeForKpisTab({ timeAgo }, kpiOptions)}
              investorId={investorHome.investor_id}
              totalsInfo={{ currency: investorHome.currency, ratesUpdatedAt: investorHome.rates_updated_at }}
              tableType="kpis_info"
              tabScroll
              exportDataURL={investorHome.kpis_info.export_url}
              canExport={investorHome.can_export}
              maxInvestments={investorHome.max_investments}
              chartTypeEventTracker={(chartType) => this.handlechartTypeChange('infos', chartType)}
              currency={investorHome.currency}
            />
          </div>
        </div>

        <div className="flex flex-column px1 mb4">
          <span className="h4 fw400 mt1">My Activity</span>
          <div className="mt1 border-bottom border-alto sm-vw-85 mb1">
            <div className="flex flex-justify-between col-5">
              <div
                className={`h5 ${currentActivityTab === 'updates' ? 'border-bottom fw400 bw-2' : 'text-light-gray cursor-pointer'} pt1 pb05`}
                onClick={() => this.setState({ currentActivityTab: 'updates' })}
                style={{ borderColor: color }}
              >
                Updates
              </div>
              <div
                className={`h5 ${currentActivityTab === 'discussions' ? 'border-bottom fw400 bw-2' : 'text-light-gray cursor-pointer'} pt1 pb05 sm-hide`}
                onClick={() => this.setState({ currentActivityTab: 'discussions' })}
                style={{ borderColor: color }}
              >
                Discussions
              </div>
              <div
                className={`h5 ${currentActivityTab === 'documents' ? 'border-bottom fw400 bw-2' : 'text-light-gray cursor-pointer'} pt1 pb05 sm-hide`}
                onClick={() => this.setState({ currentActivityTab: 'documents' })}
                style={{ borderColor: color }}
              >
                Documents
              </div>
              <div
                className={`h5 ${currentActivityTab === 'deals' ? 'border-bottom fw400 bw-2' : 'text-light-gray cursor-pointer'} pt1 pb05 sm-hide`}
                onClick={() => this.setState({ currentActivityTab: 'deals' })}
                style={{ borderColor: color }}
              >
                Deal Rooms
              </div>
            </div>
          </div>

          <div className="mt2 sm-vw-85">
            {currentActivityTab === 'updates' &&
              <InvestmentUpdatesList
                investorId={investorHome.investor_id}
                updates={investorHome.updates.data}
                nextPage={investorHome.updates.next_page}
                paginationUrl=""
                linkNewTab
              />
            }

            {currentActivityTab === 'discussions' &&
              <InvestmentUpdatesList
                investorId={investorHome.investor_id}
                updates={investorHome.updates.data}
                nextPage={investorHome.updates.nextPage}
                paginationUrl=""
                isDiscussion
                linkNewTab
              />
            }

            {currentActivityTab === 'documents' &&
              (
                investorHome.documents && investorHome.documents.length === 0 ?
                  <div className="bg-white border border-gallery bs-around mb1 items-center flex flex-justify-center" style={{ height: '70px' }}>
                    <span className="text-light-gray center">No documents available...</span>
                  </div>
                  :
                  <DataRooms dataRooms={{ all_documents: investorHome.documents, investor: {} }} hideDataRooms linkNewTab />
              )
            }

            {currentActivityTab === 'deals' &&
              (
                investorHome.dealRoomsData && investorHome.dealRoomsData.length === 0 ?
                  <div className="bg-white border border-gallery bs-around mb1 items-center flex flex-justify-center" style={{ height: '70px' }}>
                    <span className="text-light-gray center">No deal rooms available...</span>
                  </div>
                  :
                  <InvestmentDealsList
                    dealRoomsData={investorHome.dealRoomsData}
                    linkNewTab
                  />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default InvestorHome;
