import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CapPieChartWidget from './CapPieChartWidget';
import CapTableWidget from './CapTableWidget';
import ShUpdateWidget from './ShUpdateWidget';
import AvatarListWidget from './AvatarListWidget';
import SmallKpiWidget from './SmallKpiWidget';
import BigKpiWidget from './BigKpiWidget';
import ChartKpiWidget from './ChartKpiWidget';
import DataRoomWidget from './DataRoomWidget';
import MigrateToQuoroom from './MigrateToQuoroom';

class DashboardContainer extends Component {
  static propTypes = {
    widgets: PropTypes.array.isRequired,
    email: PropTypes.string.isRequired,
    connectToQuoroom: PropTypes.bool.isRequired,
    companyId: PropTypes.number.isRequired,
  }

  widgetTypes = {
    CapPieChartWidget,
    CapTableWidget,
    ShUpdateWidget,
    AvatarListWidget,
    SmallKpiWidget,
    BigKpiWidget,
    ChartKpiWidget,
    DataRoomWidget,
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('mainObjective')) {
      queryParams.delete('mainObjective');
      // Update the URL without the removed parameter
      const newUrl = `${window.location.pathname}?${queryParams}`;
      window.history.replaceState({}, '', newUrl);
    }
  }

  renderWidget(position) {
    const { widgets } = this.props;
    const widget = widgets.find(element => position === element.position);
    const WidgetComponent = this.widgetTypes[widget.component];
    return <WidgetComponent key={position} data={widget.data} />;
  }

  render() {
    const { email, connectToQuoroom, companyId } = this.props;

    return (
      <div>
        <MigrateToQuoroom email={email} connectToQuoroom={connectToQuoroom} companyId={companyId} />
        <div className="flex mxn1 sm-block">
          <div className="col-4 flex px1 sm-col-12">
            {this.renderWidget(1)}
          </div>

          <div className="col-4 flex px1 sm-col-12">
            {this.renderWidget(4)}
          </div>

          <div className="col-4 flex px1 sm-col-12">
            {this.renderWidget(7)}
            {this.renderWidget(8)}
          </div>
        </div>

        <div className="flex mxn1 sm-block">
          <div className="col-8 flex flex-wrap sm-col-12">
            {this.renderWidget(2)}
            <div className="col-6 flex px1 sm-col-12">
              {this.renderWidget(9)}
            </div>
            {this.renderWidget(3)}
            {this.renderWidget(5)}
            {this.renderWidget(6)}
          </div>

          <div className="col-4 flex px1 sm-col-12">
            {this.renderWidget(10)}
          </div>
        </div>

        <div className="flex mxn1 mb2 sm-block">
          {this.renderWidget(11)}
          {this.renderWidget(12)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  widgets: store.widgets,
  email: store.email,
  connectToQuoroom: store.connectToQuoroom,
  companyId: store.companyId,
});

export default connect(mapStateToProps)(DashboardContainer);
