import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import Button from '../../Button';
import Modal from '../../Modal';

class MigrateToQuoroom extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    connectToQuoroom: PropTypes.bool.isRequired,
    investorId: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);

    this.state = {
      email: props.email,
      password: '',
      show: false,
      loading: false,
    };

    this.initialState = this.state;
  }

  handleCloseModal = () => {
    this.setState(this.initialState);
  }

  submit = () => {
    const { email, password } = this.state;
    const { investorId, connectToQuoroom } = this.props;
    const params = { user: { email, password }, provider_type: 'Investor', provider_id: investorId };

    this.setState({ loading: true });
    if (connectToQuoroom) {
      const url = `https://my.quoroom.com/sign-in?email=${email}&password=${password}`;
      window.location.replace(url);
    } else {
      axios.post('/users/signup-to-quoroom', params).then(response => {
        if (response.data.code === '200') {
          App.State.setFlash({ name: 'notice', msg: response.data.body.message });
        } else {
          App.State.setFlash({ name: 'alert', msg: response.data.body.error });
        }
      }).catch(error => {
        App.State.setFlash({ name: 'alert', msg: error.response.data.error });
      }).finally(() => {
        this.setState(this.initialState);
        window.location.reload();
      });
    }
  }

  showMigrationModal = () => {
    const { connectToQuoroom } = this.props;
    if (connectToQuoroom) {
      const url = 'https://my.quoroom.com/sign-in';
      window.location.replace(url);
    } else {
      this.setState({ show: true });
    }
  }

  render() {
    const { show, loading, email, password } = this.state;
    const { connectToQuoroom } = this.props;

    return (
      <div>
        <div className="col-9 flex p1 sm-col-12">
          <div className="col-12 flex p1">
            <Button
              onClick={this.showMigrationModal}
              classNames="bg-blue p1 bs-around-white-light flex hover hover-layer relative rounded sm-col-12 center"
            >
              <span className="text-white h4">{ connectToQuoroom ? 'Go To Quoroom' : 'Migrate To Quoroom' }</span>
            </Button>
          </div>
        </div>
        <Modal
          show={show}
          loading={loading}
        >
          <div className="flex flex-justify-between items-center bold p2 border-bottom border-lighter-gray">
            <div className="h3 text-gray center">Migrate to Quoroom</div>
            <i className="fa fa-close h5 cursor-pointer" onClick={this.handleCloseModal} />
          </div>
          <div className="container" style={{ padding: '40px' }}>
            <div className="flex flex-column">
              <div className="pb05 text-medium-gray fw400 flex h5">
                Email Address
              </div>
              <div className="mb-25 relative">
                <input
                  type="email"
                  className="border border-lighter-gray hover hover-border-blue col-12 block placeholder-gray h5 input-height__2"
                  placeholder="Email"
                  autoCapitalize="off"
                  autoComplete="off"
                  name="email"
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-column">
              <div className="pb05 text-medium-gray fw400 flex h5">
                Password
              </div>
              <div className="mb-25 relative">
                <input
                  type="password"
                  className="border border-lighter-gray hover hover-border-blue col-12 block placeholder-gray h5 input-height__2"
                  placeholder="Password"
                  autoCapitalize="off"
                  autoComplete="off"
                  name="password"
                  value={password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-justify-end items-center p2 border-top border-lighter-gray">
            <div className="flex items-center">
              <div
                className="h5 text-blue mr2 cursor-pointer"
                onClick={this.handleCloseModal}
              >
                Cancel
              </div>
              <div
                className="h5 p1 px2 text-white rounded bg-green cursor-pointer"
                onClick={this.submit}
              >
                {loading ? 'Migrating...' : 'Migrate'}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MigrateToQuoroom;
